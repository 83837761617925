import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import classes from 'components/table/filters/filtersBoUserActivity/filtersBoUserActivity.module.scss';
import config from 'config';
import constants from 'services/constants';
import helpers from 'services/helpers';
import PropTypes from 'prop-types';
import Routing from 'routing';
import storeService from 'services/storeService';
import { holdingHelper } from 'services/holdingHelper';
import { tableColumns, initialSorting } from 'services/tableColumns';
import Button from 'backOfficeComponents/base/button/button';
import CPHEntry from 'backOfficeComponents/cphEntry/cphEntry';
import FiltersBoUserHoldingActivity from '../filtersBoUserHoldingActivity';
const BoUserActivityFilters = ({
  eventType,
  hasData,
  loadPending,
  setData,
  setModal,
  setRenderTable,
  setTableParams,
  storeFilters,
  tableParams
}) => {
  const { ready, t } = useTranslation();
  const history = useHistory();

  const [filterCph, setFilterCph] = React.useState(tableParams?.filters?.cph);
  const [filterEventType, setFilterEventType] = React.useState(tableParams?.filters?.eventType);
  const [filterDate, setFilterDate] = React.useState(tableParams?.filters?.date ? tableParams.filters.date : '');
  const [filterFromDate, setFilterFromDate] = React.useState(tableParams?.filters?.fromDate ? tableParams.filters.fromDate : '');
  const [filterToDate, setFilterToDate] = React.useState(tableParams?.filters?.toDate ? tableParams.filters.toDate : '');
  const [filterMovementType, setFilterMovementType] = React.useState(tableParams?.filters?.movementType);
  const [filterUserName, setFilterUserName] = React.useState(tableParams?.filters?.userName);
  const [cphValid, setCphValid] = React.useState(false);
  const [cphError, setCphError] = React.useState({});
  const [dateError, setDateError] = React.useState('');
  const [fromDateError, setFromDateError] = React.useState('');
  const [toDateError, setToDateError] = React.useState('');
  const [searchDisabled, setSearchDisabled] = React.useState(true);

  const eventTypeOptions = Object.entries(constants.bo.userActivity.eventTypes).map(([itemKey, itemValue]) => ({
    label: t(itemValue.label),
    name: t(itemValue.label),
    value: itemKey
  }));

  const taggingEventProperties = (row) => {
    switch (filterEventType) {
      case constants.bo.userActivity.eventTypes.births.name:
        return {
          dateField: row.content.dateOfApplication,
          quantity: row.content.applicationItems?.length
        };

      case constants.bo.userActivity.eventTypes.tagApplications.name:
        return {
          dateField: row.content.dateOfApplication,
          quantity: row.content.devices?.length + row.content.batches.reduce((total, batch) => total + batch.animalTotal, 0)
        };

      case constants.bo.userActivity.eventTypes.tagReplacements.name:
        return {
          dateField: row.content.replacementDate,
          quantity: row.content.replaceItems?.length
        };

      default:
        return {};
    }
  };

  const getDates = {
    dateMax: () => {
      const today = new Date();

      return helpers.date.formatYYYYMMDD(today);
    }
  };

  const handleChanges = {
    cph: (value) => {
      // eslint-disable-next-line no-unused-vars
      const { date, eventType, fromDate, toDate, ...storedFilters } = storeService.session.get[`tableFilters${storeFilters}`]();

      setFilterCph(value);
      setFilterEventType(null);
      setFilterDate('');
      setFilterFromDate('');
      setFilterToDate('');

      setData([]);
      storeService.session.removeAll.searchResults();

      if (value !== filterCph) {
        setRenderTable(false);
      }

      if (value?.length === 0) {
        setTableParams((prevState) => {
          // eslint-disable-next-line no-unused-vars
          const { cph, ...prevStateFilters } = prevState.filters;

          return {
            ...prevState,
            filters: prevStateFilters
          };
        });
        setCphError({});

        // eslint-disable-next-line no-unused-vars
        const { cph, ...prevStoredFilters } = storedFilters;

        storeService.session.set[`tableFilters${storeFilters}`]({
          ...prevStoredFilters
        });
      } else {
        setTableParams((prevState) => ({
          ...prevState,
          filters: {
            ...prevState.filters,
            cph: value
          }
        }));

        if (value.length === config.LENGTH_HOLDING_CPH && !holdingHelper.cph.isEnglish(value) && !holdingHelper.cph.isTest(value)) {
          setCphError({
            type: constants.errorAndWarning.error,
            inline: true,
            value: 'error.crossBorderCPH'
          });
        } else {
          setCphError({});
        }

        storeService.session.set[`tableFilters${storeFilters}`]({
          ...storedFilters,
          cph: value
        });

        history.push(Routing.boUserActivity);
      }
    },

    movementType: (value) => {
      const storedFilters = storeService.session.get[`tableFilters${storeFilters}`]();

      if (value === filterMovementType) {
        setFilterMovementType('');

        delete storedFilters.movementType;

        storeService.session.set[`tableFilters${storeFilters}`](storedFilters);
      } else {
        setFilterMovementType(value);

        storeService.session.set[`tableFilters${storeFilters}`]({
          ...storedFilters,
          movementType: value
        });
      }

      setData([]);
      storeService.session.removeAll.searchResults();

      setRenderTable(false);

      setTableParams((prevState) => ({
        ...prevState,
        filters: {
          ...prevState.filters,
          movementType: value
        }
      }));
    }
  };

  const getAnimalCount = (row) => {
    if (filterEventType === constants.bo.userActivity.eventTypes.movementReviews.name) {
      const numberOfAnimalsInBatches = row.content.reviewMovement.acceptedBatches?.length > 0 ? row.content.reviewMovement.acceptedBatches.reduce((total, row) => total + parseInt(row.animalTotal), 0) : 0;
      const numberOfIndividualAnimals = row.content.reviewMovement.acceptedDevices?.length;

      return numberOfAnimalsInBatches + numberOfIndividualAnimals;
    } else if (filterEventType === constants.bo.userActivity.eventTypes.deaths.name) {
      const numberOfBatchAnimalTotal = row.content.batchDetails.reduce((total, item) => {
        return total + item.animalTotal;
      }, 0);

      return numberOfBatchAnimalTotal + row.content.killedDevices?.length;
    } else if (filterEventType === constants.bo.userActivity.eventTypes.animalsAdded.name) {
      return row.content.devicesWithDetail?.length;
    }

    return null;
  };

  const runRequest = () => {
    if (!filterFromDate) {
      setFilterFromDate('');
    }
    if (!filterToDate) {
      setFilterToDate('');
    }

    storeService.session.set[`tableFilters${storeFilters}PageIndex`](0);
    storeService.session.removeAll.searchResults();
    setData([]);

    setTableParams((prevState) => ({
      ...prevState,
      columns: tableColumns.bo.userActivity[filterEventType],
      filters: {
        userName: filterUserName,
        eventType: filterEventType,
        fromDate: filterFromDate,
        toDate: filterToDate,
        movementType: filterMovementType,
        useComponent: constants.bo.userActivity.eventTypes[filterEventType].useComponent
      },
      page: {
        ...prevState.page,
        index: 0
      },
      request: {
        ...prevState.request,
        params: {
          ...prevState.request.params,
          cph: filterCph,
          eventType: filterEventType,
          fromDate: filterFromDate,
          toDate: filterToDate,
          movementType: filterMovementType
        },
        processData: (data) => data.map((row) => ({
          ...row,
          status: row.isFullUndone ? constants.status.cancelled : row.requestStatus,
          taggingDate: taggingEventProperties(row).dateField,
          taggingQuantity: taggingEventProperties(row).quantity,
          notificationQuantity: getAnimalCount(row),
          deathQuantity: getAnimalCount(row),
          animalsAddedQuantity: getAnimalCount(row),
          annualInventory: {
            ...row.content?.annualInventorySpecies?.[0],
            total: row.content?.annualInventorySpecies?.[0].annualInventoryStocks?.[0].quantity
          }
        }))
      },
      sort: {
        by: initialSorting.bo.userActivity[filterEventType].accessor,
        direction: initialSorting.bo.userActivity[filterEventType].direction
      }
    }));
    setRenderTable(true);
  };

  useEffect(() => {
    if (
      !eventType.url || loadPending || dateError !== '' || fromDateError !== '' || toDateError !== '' || hasData ||
      (eventType?.showDate && !filterDate) ||
      (eventType?.showDateRange && (!filterFromDate || !filterToDate))
    ) {
      setSearchDisabled(true);
    } else {
      setSearchDisabled(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    loadPending,
    hasData,
    eventType,
    filterDate,
    filterFromDate,
    filterToDate,
    fromDateError,
    toDateError
  ]);

  useEffect(() => {
    if ((eventType.url === Routing.boUserActivityAssociatedHoldings || eventType.url === Routing.boUserActivityHoldingDetails) && hasData) {
      runRequest();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventType]);

  useEffect(() => {
    const source = axios.CancelToken.source();

    if (cphValid) {
      helpers.get.holdingsInBusiness(source.token, filterCph)
        .then((res) => {
          const username = res.data.data.id;

          storeService.session.set.tableFiltersUser(username);
          setFilterUserName(username);
        })
        .catch(() => {
          storeService.session.remove.tableFiltersUser();
        });
    }

    return () => {
      source.cancel();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cphValid]);

  useEffect(() => {
    if (filterEventType) {
      setTableParams((prevState) => ({
        ...prevState,
        columns: tableColumns.bo.userActivity[filterEventType],
        request: {
          ...prevState.request,
          url: constants.bo.userActivity.eventTypes[filterEventType].endpoint,
          params: {
            ...prevState.request.params,
            cph: filterCph,
            eventType: filterEventType,
            fromDate: filterFromDate,
            toDate: filterToDate,
            movementType: filterMovementType
          },
          processData: (data) => data.map((row) => ({
            ...row,
            status: row.isFullUndone ? constants.status.cancelled : row.requestStatus,
            taggingDate: taggingEventProperties(row).dateField,
            taggingQuantity: taggingEventProperties(row).quantity,
            notificationQuantity: getAnimalCount(row),
            deathQuantity: getAnimalCount(row),
            animalsAddedQuantity: getAnimalCount(row),
            annualInventory: {
              ...row.content?.annualInventorySpecies?.[0],
              total: row.content?.annualInventorySpecies?.[0].annualInventoryStocks?.[0].quantity
            }
          }))
        }
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {ready &&
        <div className={classes.filters}>
          <div>
            <div className={classes.cph}>
              <CPHEntry
                addressClassNames={['inline']}
                cphError={cphError}
                data={filterCph}
                disabled={loadPending}
                errorClassNames={['inline-bo']}
                format={{
                  address: constants.address.format.inline,
                  name: constants.address.format.inline
                }}
                hideTooltip={true}
                labelText="label.holdingNumber"
                name="cph"
                setCphValid={setCphValid}
                setData={handleChanges.cph}
                setModal={setModal}
                setUserName={setFilterUserName}
              />
            </div>

            {cphValid && !cphError?.type &&
              <>
                <FiltersBoUserHoldingActivity
                  dateError={dateError}
                  eventType={eventType}
                  eventTypeOptions={eventTypeOptions}
                  filterDate={filterDate}
                  filterEventType={filterEventType}
                  filterFromDate={filterFromDate}
                  filterToDate={filterToDate}
                  fromDateError={fromDateError}
                  getDates={getDates}
                  history={history}
                  loadPending = {loadPending}
                  setData={setData}
                  setDateError={setDateError}
                  setFilterDate={setFilterDate}
                  setFilterEventType={setFilterEventType}
                  setFilterFromDate={setFilterFromDate}
                  setFilterToDate={setFilterToDate}
                  setFromDateError={setFromDateError}
                  setRenderTable={setRenderTable}
                  setTableParams={setTableParams}
                  setToDateError={setToDateError}
                  storeFilters={storeFilters}
                  toDateError={toDateError}
                ></FiltersBoUserHoldingActivity>
              </>
            }
          </div>

          {cphValid && filterEventType &&
            <>
              <div className={classes.submitButton}>
                <Button
                  buttonType="primary"
                  disabled={searchDisabled}
                  id="movementSubmitButton"
                  label="button.search"
                  onClick={runRequest}
                />
              </div>
            </>
          }
        </div>
      }
    </>
  );
};

BoUserActivityFilters.propTypes = {
  eventType: PropTypes.object.isRequired,
  hasData: PropTypes.bool.isRequired,
  loadPending: PropTypes.bool.isRequired,
  setData: PropTypes.func.isRequired,
  setModal: PropTypes.func.isRequired,
  setRenderTable: PropTypes.func.isRequired,
  setTableParams: PropTypes.func.isRequired,
  storeFilters: PropTypes.string.isRequired,
  tableParams: PropTypes.object.isRequired
};

export default BoUserActivityFilters;
