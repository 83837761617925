import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Prompt } from 'react-router';
import bff, { bffResponse, bffStatus } from 'services/bff';
import classes from 'backOffice/paperDetails/paperDetails.module.scss';
import config from 'config';
import constants from 'services/constants';
import errors from 'services/errors';
import helpers from 'services/helpers';
import navigation from 'services/navigation';
import PropTypes from 'prop-types';
import Routing from 'routing';
import storeService from 'services/storeService';
import Button from 'backOfficeComponents/base/button/button';
import Confirmation from 'components/base/confirmation/confirmation';
import ErrorLabel from 'components/base/errorLabel/errorLabel';
import Exemptions from 'backOfficeComponents/sections/exemptions/exemptions';
import LocationAddressDate from 'backOfficeComponents/sections/locationAddressDate/locationAddressDate';
import ModalConfirm from 'components/base/modalConfirm/modalConfirm';
import PageTitle from 'backOfficeComponents/base/pageTitle/pageTitle';
import PaperAnimalBatch from 'backOfficeComponents/sections/paperAnimalBatch/paperAnimalBatch';
import PaperAnimalIndividual from 'backOfficeComponents/sections/paperAnimalIndividual/paperAnimalIndividual';
import PaperTransport from 'backOfficeComponents/sections/paperTransport/paperTransport';
import SourceDocument from 'backOfficeComponents/sections/sourceDocument/sourceDocument';
import UserNotes from 'backOfficeComponents/sections/notes/userNotes';

const defaultPaperSource = 'paper';

const createDocumentSection = (resData) => ({
  documentId: resData.scannedDocumentId,
  documentType: resData.documentSourceFormat,
  documentSource: defaultPaperSource,
  paperId: resData.id,
  receivedDate: resData.receivedDate.substring(0, 10),
  species: resData.species.toLowerCase(),
  speciesId: helpers.species.nameToId(resData.species),
  permitMove: false
});

const createDepartureSection = (departureDetail) => ({
  CPH: helpers.replaceNull(departureDetail.holding),
  date: helpers.date.concatToStringDate(departureDetail.departureDate.year, departureDetail.departureDate.month, departureDetail.departureDate.day),
  manualCPH: helpers.replaceNull(departureDetail.manualEntryHoldingAddress)
});

const createTransportSection = (transportDetail) => ({
  transportedBy: transportType(helpers.replaceNull(transportDetail.transporterType)),
  transportVehicleRegistrationNo: helpers.replaceNull(transportDetail.transportVehicleRegistrationNo),
  transporterName: helpers.replaceNull(transportDetail.transportHaulierName),
  transportAuthorisationNumber: helpers.replaceNull(transportDetail.transporterAuthNumber)
});

const createDestinationSection = (destinationDetail) => ({
  CPH: helpers.replaceNull(destinationDetail.holding),
  date: helpers.date.concatToStringDate(destinationDetail.arrivalDate.year, destinationDetail.arrivalDate.month, destinationDetail.arrivalDate.day),
  manualCPH: helpers.replaceNull(destinationDetail.manualEntryHoldingAddress),
  numberReceived: helpers.replaceNull(destinationDetail.totalAnimalsReceived)
});

const createBatchesSection = (batches) => {
  return batches.map((batch) => ({
    animalTotal: batch.animalTotal,
    batchNumber: batch.batchNumber,
    description: ''
  }));
};

const createDevicesSection = (devices) => {
  return devices.map((device) => ({
    tagNumber: device.tagNumber || device.rfid,
    uuid: helpers.generateUUID(),
    status: ''
  }));
};

const transportType = (inValue) => {
  switch (inValue) {
    case constants.option.transporterType.departureKeeperType:
      return constants.option.transporter.departureKeeper;
    case constants.option.transporterType.destinationKeeperType:
      return constants.option.transporter.destinationKeeper;
    case constants.option.transporterType.haulierType:
      return constants.option.transporter.haulier;
    default:
      return '';
  }
};

const getErrorIndexes = (incomingError) => {
  const integerRegex = /\d+/g;
  return String(incomingError).match(integerRegex);
};

const BoPaperDetails = ({
  location,
  setModal
}) => {
  const { ready, t } = useTranslation();
  const history = useHistory();

  const departureCPH = location?.state?.data?.departureCPH ? location.state.data.departureCPH : '';
  const destinationCPH = location?.state?.data?.destinationCPH ? location.state.data.destinationCPH : '';
  const species = location?.state?.data?.species ? location.state.data.species : '';
  const speciesId = helpers.species.nameToId(species);

  const sessionDataChanged = storeService.session.get.dataChanged();

  const ERR_TEMPLATE = { type: '', value: '' };

  const [movementType, setMovementType] = React.useState(constants.option.movement.on);
  const [pageErrors, setPageErrors] = React.useState([]);
  const [undoStatus, setUndoStatus] = React.useState(false);
  const [postType, setPostType] = React.useState(constants.postType.CREATE);
  const [needUpdate, setNeedUpdate] = React.useState(false);

  const emptyStates = {
    arriveData: {
      CPH: destinationCPH,
      date: '',
      manualCPH: '',
      numberReceived: ''
    },

    animalDetails: [],

    batchList: [],

    departData: {
      CPH: departureCPH,
      date: '',
      manualCPH: ''
    },

    sourceDocument: {
      documentId: '',
      documentType: '',
      receivedDate: '',
      documentSource: defaultPaperSource,
      paperId: '',
      speciesId,
      permitMove: false
    },

    sourceDocumentProps: {
      documentIdError: ERR_TEMPLATE,
      documentSourceError: ERR_TEMPLATE,
      documentTypeError: ERR_TEMPLATE,
      isValid: false,
      receivedDateError: ERR_TEMPLATE,
      validation: 0,
      speciesError: ERR_TEMPLATE
    },

    transportData: {
      transportedBy: '',
      transportVehicleRegistrationNo: '',
      transporterName: '',
      transportAuthorisationNumber: ''
    }
  };

  const [sourceDocumentInit, setSourceDocumentInit] = React.useState(emptyStates.sourceDocument);
  const [sourceDocument, setSourceDocument] = React.useState({ ...sourceDocumentInit, speciesId });
  const [sourceDocumentProps, setSourceDocumentProps] = React.useState(emptyStates.sourceDocumentProps);

  const [departDataInit, setDepartDataInit] = React.useState({
    ...emptyStates.departData,
    CPH: departureCPH
  });
  const [departData, setDepartData] = React.useState(departDataInit);

  const [transportDataInit, setTransportDataInit] = React.useState(emptyStates.transportData);
  const [transportData, setTransportData] = React.useState(transportDataInit);

  const [arriveDataInit, setArriveDataInit] = React.useState({
    ...emptyStates.arriveData,
    CPH: destinationCPH
  });
  const [arriveData, setArriveData] = React.useState(arriveDataInit);

  const [optionsInit, setOptionsInit] = React.useState([]);
  const [options, setOptions] = React.useState([]);

  const [batchListInit, setBatchListInit] = React.useState(emptyStates.batchList);
  const [batchList, setBatchList] = React.useState(batchListInit);

  const [animalDetailsInit, setAnimalDetailsInit] = React.useState(emptyStates.animalDetails);
  const [animalDetails, setAnimalDetails] = React.useState(animalDetailsInit);

  const [isExemptionsValid, setIsExemptionsValid] = React.useState(true);

  const [exemptionsValidation, setExemptionsValidation] = React.useState(0);

  const [userNotes, setUserNotes] = React.useState([]);
  const [departureDateError, setDepartureDateError] = React.useState(ERR_TEMPLATE);
  const [arrivalDateError, setArrivalDateError] = React.useState(ERR_TEMPLATE);
  const [departureHoldingError, setDepartureHoldingError] = React.useState(ERR_TEMPLATE);
  const [destinationHoldingError, setDestinationHoldingError] = React.useState(ERR_TEMPLATE);
  const [destinationDetailTotalAnimalsReceivedError, setDestinationDetailTotalAnimalsReceivedError] = React.useState(ERR_TEMPLATE);
  const [transportDataError, setTransportDataError] = React.useState(ERR_TEMPLATE);
  const [haulierNameError, setHaulierNameError] = React.useState(ERR_TEMPLATE);
  const [transportAuthNumberError, setTransportAuthNumberError] = React.useState(ERR_TEMPLATE);
  const [validationState, setValidationState] = React.useState(0);
  const [pageWarnings, setPageWarnings] = React.useState([]);
  const [validateCount, setValidateCount] = React.useState(0);

  const [pending, setPending] = React.useState(false);
  const [submitted, setSubmitted] = React.useState(false);

  const [dataChanged, setDataChanged] = React.useState(sessionDataChanged ? sessionDataChanged : false);

  const [requestId, setRequestId] = React.useState(null);
  const [asyncId, setAsyncId] = React.useState(null);

  const setValidation = (func, event) => {
    setValidationState((prevState) => prevState === 2 ? 0 : prevState);
    func(event);
  };

  const resetExemptions = React.useCallback(() => {
    const exemptions = options;
    exemptions.forEach((exemption) => {
      exemption.value = false;
      optionsInit.forEach((option) => {
        if (exemption.id === option.processingFlagId) {
          exemption.value = true;
        }
      });
    });

    setOptions(exemptions);
  }, [options, optionsInit]);

  const getCount = () => {
    const sdp = sourceDocumentProps;
    let count = sdp.documentIdError?.value?.length ? 1 : 0;
    count += sdp.receivedDateError?.value?.length ? 1 : 0;
    count += sdp.speciesError?.value?.length ? 1 : 0;
    count += sdp.documentTypeError?.value?.length ? 1 : 0;
    count += sdp.documentSourceError?.value?.length ? 1 : 0;
    return count;
  };

  const resetPage = React.useCallback((emptySession) => {
    if (emptySession) {
      setPostType(constants.postType.CREATE);
      storeService.session.set.confirmSubmitType(constants.postType.CREATE);
    }
    setAsyncId(null);
    storeService.session.set.confirmAsyncId(null);
    setSourceDocument(emptySession ? emptyStates.sourceDocument : { ...sourceDocumentInit, speciesId: null });
    setDepartData(emptySession ? emptyStates.departData : { ...departDataInit, CPH: '' });
    setTransportData(emptySession ? emptyStates.transportData : transportDataInit);
    if (movementType === constants.option.movement.on) {
      setArriveData(emptySession ? emptyStates.arriveData : { ...arriveDataInit, CPH: '' });
    } else {
      setMovementType(constants.option.movement.on);
    }
    resetExemptions();
    setBatchList(emptySession ? emptyStates.batchList : batchListInit);
    setAnimalDetails(emptySession ? emptyStates.animalDetails : animalDetailsInit);
    clear.errors();
    setValidationState(0);
    setUserNotes([]);
    if (!emptySession && sourceDocument.paperId !== '') {
      validatePaperMovement();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options]);

  const createErrors = (errorList) => {
    if (errorList?.length === 0) {
      setPageErrors(errorList);
      return;
    }

    const uniqueErrors = [...new Set(errorList.map((errorItem) => errorItem.target))];

    uniqueErrors.forEach((errorItem) => {
      const matchTargets = errorList.filter((error) => error.target === errorItem);
      const lastError = matchTargets[matchTargets?.length - 1];

      lookupErrorWarningField(
        lastError,
        {
          type: constants.errorAndWarning.error,
          value: lastError.message
        }
      );

      const errArray = pageErrors;
      errArray.push(lastError);
      setPageErrors(errArray);
    });
  };

  const createWarnings = (warnings) => {
    if (warnings?.length === 0) {
      setPageWarnings(warnings);
      return;
    }
    const uniqueWarnings = [...new Set(warnings.map((warning) => warning.target))];

    uniqueWarnings.forEach((warningItem) => {
      const matchTargets = warnings.filter((warning) => warning.target === warningItem);
      const lastWarning = matchTargets[matchTargets?.length - 1];
      lookupErrorWarningField(
        lastWarning,
        {
          type: constants.errorAndWarning.warning,
          value: lastWarning.message
        }
      );
      const warningArray = pageWarnings;
      warningArray.push(lastWarning);
      setPageWarnings(warningArray);
    });
  };

  const validateBO = () => {
    bff
      .post('/validateBO', getSubmissionParams.create())
      .then((res) => {
        createErrors(res.data.errors);
        createWarnings(res.data.warnings);
        setSourceDocumentProps((prevState) => ({
          ...prevState,
          validation: 0
        }));

        const invalidBatches = res.data.errors.filter((error) => error.code === '30010').map((error) => {
          const batchItem = error.target.split('.')[1];
          return parseInt(batchItem.substring(
            batchItem.indexOf('[') + 1,
            batchItem.lastIndexOf(']')
          ));
        });

        const checkCodes = ['30019', '3027', '30022'];

        const invalidIndividualAnimals = res.data.errors.filter((error) => checkCodes.includes(error.code)).map((error) => {
          const animalItem = error.target.split('.')[1];
          return parseInt(animalItem.substring(
            animalItem.indexOf('[') + 1,
            animalItem.lastIndexOf(']')
          ));
        });

        setBatchList((prevState) => ([
          ...prevState.map((batch, index) => ({
            ...batch,
            valid: invalidBatches.includes(index) ? 'Invalid' : 'Valid'
          }))
        ]));

        setAnimalDetails((prevState) => ([
          ...prevState.map((animal, index) => ({
            ...animal,
            valid: invalidIndividualAnimals.includes(index) ? 'Invalid' : 'Valid'
          }))
        ]));

        setValidationState(2);
      })
      .catch((error) => {
        errors.BFF(error, setModal);
      });
  };

  const lookupErrorWarningField = (error, errorObject, errorIndexes) => {
    const invalidateSourceDocumentProps = (name, newValue) => {
      setSourceDocumentProps((prevState) => ({
        ...prevState,
        [name]: newValue,
        isValid: false
      }));
    };

    if (!error.target) {
      return true;
    }
    switch (error.target) {
      case 'movementDocument.departureDetail.departureDate':
        setDepartureDateError(errorObject);
        break;
      case 'movementDocument.destinationDetail.arrivalDate':
        setArrivalDateError(errorObject);
        break;
      case 'receivedDate':
        invalidateSourceDocumentProps('receivedDateError', errorObject);
        break;
      case 'movementDocument.departureDetail.holding':
        setDepartureHoldingError(errorObject);
        break;
      case 'movementDocument.destinationDetail.holding':
        setDestinationHoldingError(errorObject);
        break;
      case 'movementDocument.destinationDetail.totalAnimalsReceived':
        setDestinationDetailTotalAnimalsReceivedError(errorObject);
        break;
      case 'species':
        invalidateSourceDocumentProps('speciesError', errorObject);
        break;
      case 'documentSourceFormat':
        invalidateSourceDocumentProps('documentTypeError', errorObject);
        break;
      case 'documentSource':
        invalidateSourceDocumentProps('documentSourceError', errorObject);
        break;
      case 'documentId':
        invalidateSourceDocumentProps('documentIdError', errorObject);
        break;
      case 'movementDocument.transportDetail.transporterType':
        setTransportDataError(errorObject);
        break;
      case 'movementDocument.transportDetail.transportHaulierName':
        setHaulierNameError(errorObject);
        break;
      case 'movementDocument.transportDetail.transporterAuthNumber':
        setTransportAuthNumberError(errorObject);
        break;
      case String(error.target.match(/^movementGroups\[\d+]\.batches\[\d+].animalTotal$/)):
        errorIndexes = getErrorIndexes(error.target);
        setValidation(setBatchList, helpers.error.addToAnimalObjects(batchList, errorIndexes, { type: error.typeIdentifier, value: error.message }, 'description'));
        break;
      case String(error.target.match(/^movementGroups\[\d+]\.batches\[\d+]$/)):
        errorIndexes = getErrorIndexes(error.target);
        setValidation(setBatchList, helpers.error.addToAnimalObjects(batchList, errorIndexes, { type: error.typeIdentifier, value: error.message }, 'description'));
        break;
      case String(error.target?.match(/^movementGroups\[\d+]\.devices\[\d+]*$/)):
      case String(error.target?.match(/^movementGroups\[\d+]\.devices\[\d+].tagNumber*$/)):
      case String(error.target?.match(/^movementGroups\[\d+]\.devices\[\d+].rfid*$/)):
        errorIndexes = getErrorIndexes(error.target);
        setAnimalDetails(
          (prevState) => helpers.error.addToAnimalObjects(
            prevState,
            errorIndexes,
            { type: error.typeIdentifier, value: { type: 'Error', value: error.message } },
            'status'
          )
        );
        break;
      default:
        return false;
    }
    return true;
  };

  const validatePaperMovement = () => {
    setValidationState(1);
    clear.animalStatus();
    clear.errors();
    setSourceDocumentProps((prevState) => ({
      ...prevState,
      validation: sourceDocumentProps.validation + 1
    }));
  };

  const processPermitMoveErrors = (res) => {
    storeService.session.set.confirmPmMove(sourceDocument.permitMove);
    if (sourceDocument.permitMove) {
      return res.data.errors?.filter((error) => !error.target?.includes('movementDocument.destinationDetail.'));
    }

    return res.data.errors;
  };

  const handleSubmissionResponse = (res) => {
    const data = res.data;
    const aId = bffResponse.asyncId(data);
    setAsyncId(aId);
    storeService.session.set.confirmAsyncId(aId);
    storeService.session.set.confirmPaperId(bffResponse.paperId(data));
    storeService.session.set.confirmMovementReference(bffResponse.movementRef(data));
    const rId = bffResponse.requestId(data);
    storeService.session.set.confirmRequestId(rId);
    setRequestId(rId);
    storeService.session.set.confirmPollingStatus(bffResponse.status(data));
    storeService.session.set.confirmSubmitType(postType);
    const paper_Id = storeService.session.get.confirmPaperId();
    const movement_Ref = storeService.session.get.confirmMovementReference();
    const status = storeService.session.get.confirmPollingStatus();

    if ((status === null || status === constants.status.completed_error) && paper_Id && !movement_Ref) {
      helpers.get.paperMovements(paper_Id)
        .then((res2) => {
          if (helpers.response.isValid(res2.data, setModal)) {
            res2.data.errors = processPermitMoveErrors(res2);
            storeService.session.set.confirmPollingErrors(bffResponse.errors(res2.data));
            storeService.session.set.confirmPollingWarnings(bffResponse.warnings(res2.data));
            storeService.session.set.confirmPollingStatus(bffResponse.status(res2.data));
            history.push(Routing.boMovementsConfirm);
          }
        });
    } else {
      storeService.session.set.confirmPollingErrors(bffResponse.errors(data));
      storeService.session.set.confirmPollingWarnings(bffResponse.warnings(data));
      history.push(Routing.boMovementsConfirm);
    }
  };

  const submit = (submitType) => {
    switch (submitType) {
      case constants.postType.CREATE:
        setSubmitted(true);
        setPending(true);
        storeService.session.removeAll.confirm();

        bff
          .post('/paperDocumentCreate', getSubmissionParams.create())
          .then((res) => {
            setPending(false);
            setDataChanged(false);
            storeService.session.remove.dataChanged();
            handleSubmissionResponse(res);
          })
          .catch((error) => {
            setPending(false);
            errors.BFF(error, setModal);
          });
        break;

      case constants.postType.UPDATE:
        setSubmitted(true);
        setPending(true);
        storeService.session.removeAll.confirm();

        bff
          .post('/paperDocumentUpdate', getSubmissionParams.create())
          .then((res) => {
            setPending(false);
            setDataChanged(false);
            storeService.session.remove.dataChanged();
            handleSubmissionResponse(res);
          })
          .catch((error) => {
            setPending(false);
            errors.BFF(error, setModal);
          });

        break;

      case constants.postType.UNDO:
        setSubmitted(true);
        setPending(true);

        setUndoStatus(false);
        setPostType(constants.postType.UNDO);
        storeService.session.removeAll.confirm();

        bff
          .post('/paperDocumentUndo', getSubmissionParams.undo())
          .then((res) => {
            const { data } = res;
            setPending(false);
            setDataChanged(false);
            storeService.session.remove.dataChanged();
            const rId = bffResponse.requestId(data);
            storeService.session.set.confirmRequestId(rId);
            setRequestId(rId);
            const status = bffResponse.status(data);
            storeService.session.set.confirmPollingStatus(status);
            storeService.session.set.confirmStatus(status);
            storeService.session.set.confirmPollingErrors(bffResponse.errors(data));
            storeService.session.set.confirmSubmitType(constants.postType.UNDO);
            history.push(Routing.boMovementsConfirm);
          })
          .catch((error) => {
            setPending(false);
            errors.BFF(error, setModal);
          });
        break;

      default:
        break;
    }
  };

  const dataHasChanged = () => {
    storeService.session.set.dataChanged(true);
    setDataChanged(true);
  };

  const recordAnother = () => {
    setSubmitted(false);
    setValidationState(0);
    resetPage(true);
    history.push(navigation.movements());
  };

  const getSubmissionParams = {
    create: () => {
      const createDepartureDetail = (departure) => ({
        holding: departure.CPH,
        movementType: '',
        manualEntryHoldingAddress: departure.manualCPH,
        departureDate: {
          day: helpers.date.splitStringDate(departure.date).day,
          month: helpers.date.splitStringDate(departure.date).month,
          year: helpers.date.splitStringDate(departure.date).year
        }
      });

      const createArrivalDetail = (arrival) => ({
        totalAnimalsReceived: arrival.numberReceived,
        holding: arrival.CPH,
        manualEntryHoldingAddress: arrival.manualCPH,
        arrivalDate: {
          day: helpers.date.splitStringDate(arrival.date).day,
          month: helpers.date.splitStringDate(arrival.date).month,
          year: helpers.date.splitStringDate(arrival.date).year
        }
      });

      const createTransportDetail = (transport) => ({
        transportType: transport.transportedBy,
        transporterAuthNumber: transport.transportAuthorisationNumber,
        transportHaulierName: transport.transporterName,
        transportVehicleRegistrationNo: transport.transportVehicleRegistrationNo
      });

      const removeManualCPH = (data) => ({
        ...data,
        manualCPH: ''
      });

      return {
        validated: (pageErrors?.length === 0) && (pageWarnings?.length === 0),
        id: sourceDocument.paperId,
        movementId: sourceDocument.paperId,
        documentId: sourceDocument.documentId,
        documentSource: sourceDocument.documentSource,
        documentSourceFormat: sourceDocument.documentType,
        receivedDate: sourceDocument.receivedDate,
        species: helpers.species.idToName(sourceDocument.speciesId).toLowerCase(),
        comment: sourceDocument.permitMove ? departData.manualCPH : '',
        movementDocument: {
          departureDetail: sourceDocument.permitMove ? createDepartureDetail(removeManualCPH(departData)) : createDepartureDetail(departData),
          destinationDetail: sourceDocument.permitMove ? createArrivalDetail(removeManualCPH(departData)) : createArrivalDetail(arriveData),
          transportDetail: createTransportDetail(transportData)
        },
        exemptions: (options?.length) ? options.filter((o) => o.value) : [],
        movementGroups: {
          batchList: batchList,
          animalDetails: animalDetails
        },
        notes: userNotes.filter((note) => note.canRemove).map((note) => ({
          noteDate: note.date,
          notes: note.text
        }))
      };
    },

    undo: () => {
      return {
        poll: config.POLLS_ENABLED,
        paperId: sourceDocument.paperId
      };
    }
  };

  const pageContent = {
    sourceDocumentSection: () => {
      return (
        <SourceDocument
          data={sourceDocument}
          id="sourceDocument"
          props={sourceDocumentProps}
          setData={(event) => setValidation(setSourceDocument, event)}
          setDataChanged={dataHasChanged}
          setProps={setSourceDocumentProps}
        />
      );
    },

    departureLocationSection: () => {
      return (
        <LocationAddressDate
          cphError={departureHoldingError}
          data={departData}
          dateError={departureDateError}
          dateLabel="label.dateDeparture"
          id="departureLocation"
          isPaper={true}
          labelText={ sourceDocument.permitMove ? 'movements.permitMove.cphNumber' : 'label.cphNumber' }
          numberReceivedError={sourceDocument.permitMove ? destinationDetailTotalAnimalsReceivedError : null}
          numberReceivedLabel={sourceDocument.permitMove ? 'movements.permitMove.numberMoved' : ''}
          panelTitle={sourceDocument.permitMove ? 'boApp.sectionTitles.locations' : 'boApp.sectionTitles.departureLocation' }
          pmMove={sourceDocument.permitMove}
          searchedData={departData}
          setData={(event) => setValidation(setDepartData, event)}
          setDataChanged={dataHasChanged}
          setDateError={setDepartureDateError}
          setModal={setModal}
          speciesId={sourceDocument.speciesId}
        />
      );
    },

    transportSection: () => {
      return (
        <PaperTransport
          haulierNameError={haulierNameError}
          id="paperTransport"
          panelTitle="boApp.sectionTitles.transportDetails"
          setDataChanged={dataHasChanged}
          setHaulierNameError={setHaulierNameError}
          setTransportAuthNumberError={setTransportAuthNumberError}
          setTransportDetails={(event) => setValidation(setTransportData, event)}
          setTransportDetailsError={setTransportDataError}
          transportAuthNumberError={transportAuthNumberError}
          transportDetails={transportData}
          transportDetailsError={transportDataError}
        />
      );
    },

    arrivalLocationSection: () => {
      return (
        <LocationAddressDate
          cphError={destinationHoldingError}
          data={arriveData}
          dateError={arrivalDateError}
          dateLabel="label.arrivalDate"
          id="receivingLocation"
          isPaper={true}
          labelText="label.cphNumber"
          numberReceivedError={destinationDetailTotalAnimalsReceivedError}
          numberReceivedLabel="label.numberReceived"
          panelTitle="label.receivingLocation"
          searchedData={arriveData}
          setData={(event) => setValidation(setArriveData, event)}
          setDataChanged={dataHasChanged}
          setDateError={setArrivalDateError}
          setModal={setModal}
          speciesId={sourceDocument.speciesId}
        />
      );
    },

    exemptionsSection: () => {
      return (
        <>
          {sourceDocument.speciesId !== 0 &&
            <Exemptions
              exemptionsValidation={exemptionsValidation}
              id="exemptions"
              isExemptionsValid={isExemptionsValid}
              needUpdate={needUpdate}
              options={options}
              panelTitle="label.exemptions"
              permitMove={sourceDocument.permitMove}
              setDataChanged={dataHasChanged}
              setExemptionsValidation={setExemptionsValidation}
              setIsExemptionsValid={setIsExemptionsValid}
              setModal={setModal}
              setOptions={(event) => setValidation(setOptions, event)}
              speciesId={sourceDocument.speciesId}
            />
          }
        </>
      );
    },

    animalBatchSection: () => {
      return (
        <PaperAnimalBatch
          batchList={batchList}
          setBatchList={setBatchList}
          setData={setBatchList}
          setDataChanged={dataHasChanged}
          setModal={setModal}
          speciesId={sourceDocument.speciesId ? sourceDocument.speciesId : constants.species.id.SHEEP}
        />
      );
    },

    individualAnimalsSection: () => {
      return (
        <PaperAnimalIndividual
          animalDetails={animalDetails}
          pending={pending}
          setAnimalDetails={setAnimalDetails}
          setData={setAnimalDetails}
          setDataChanged={dataHasChanged}
          setModal={setModal}
          setPending={setPending}
          setValidationState={setValidationState}
          speciesId={sourceDocument.speciesId}
        />
      );
    },

    userNotesSection: () => {
      return (
        <UserNotes
          id="userNotes"
          notes={userNotes}
          setDataChanged={dataHasChanged}
          setModal={setModal}
          setNotes={setUserNotes}
        />
      );
    },

    pendingDisplay: () => {
      return (
        <ErrorLabel
          id="errorLabel"
          isWarning={true}
          label="label.checkingValidation"
        />
      );
    },

    resultsDisplay: () => {
      if (validateCount > 0) {
        return (
          <div className={classes.notification + ' ' + classes.alert}>
            <p className={classes.notificationTitle}>
              <i className={'bi bi-exclamation-triangle ' + classes.icon} /> {t('error.validateSourceDocumentSectionError')}
            </p>
          </div>
        );
      }

      if ((pageErrors?.length + pageWarnings?.length) > 0) {
        return (
          <div className={classes.notification + ' ' + classes.alert}>
            <p className={classes.notificationTitle}>
              <i className={'bi bi-exclamation-triangle ' + classes.icon} /> {t('error.validateOtherSectionError')}
            </p>
          </div>
        );
      }
      return (
        <div className={classes.notification + ' ' + classes.success}>
          <p className={classes.notificationTitle}>
            <i className={'bi bi-check-square ' + classes.icon} /> {t('label.validationSuccessful')}
          </p>
          <p>{t('label.submitMovement')}</p>
        </div>
      );
    }
  };

  const clear = {
    animalStatus: () => {
      setAnimalDetails((prevState) => prevState.map((animal) => ({
        ...animal,
        status: ''
      })));
    },

    errors: () => {
      setPageErrors([]);
      setSourceDocumentProps(emptyStates.sourceDocumentProps);

      setDepartureHoldingError(ERR_TEMPLATE);
      setDepartureDateError(ERR_TEMPLATE);

      setDestinationHoldingError(ERR_TEMPLATE);
      setArrivalDateError(ERR_TEMPLATE);

      setTransportDataError(ERR_TEMPLATE);
      setHaulierNameError(ERR_TEMPLATE);
      setTransportAuthNumberError(ERR_TEMPLATE);

      setDestinationDetailTotalAnimalsReceivedError(ERR_TEMPLATE);
    }
  };

  useEffect(() => {
    if (location.pathname.substring(0, Routing.boMovements.length) === Routing.boMovements) {
      storeService.session.removeAll.movement();
      setSubmitted(false);
      setValidationState(0);
      setRequestId(null);
      const needReset = (location.pathname.substring(0, Routing.boMovementsConfirm.length) !== Routing.boMovementsConfirm);
      resetPage(needReset);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  useEffect(() => {
    setDepartData({ ...departData, CPH: departureCPH });
    setArriveData({ ...arriveData, CPH: destinationCPH });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [movementType]);

  useEffect(() => {
    if (sourceDocumentProps.isValid) {
      setValidateCount(0);
      validateBO();
    } else {
      const count = getCount();
      setValidateCount(count);
      if (count) {
        setValidationState(2);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sourceDocumentProps.isValid]);

  useEffect(() => {
    const count = getCount();
    setValidateCount(count);
    if (count) {
      setValidationState(2);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sourceDocumentProps]);

  useEffect(() => {
    const appContent = document.getElementById('appContent');

    setTimeout(() => {
      const fixedActionsHeight = document.getElementById('fixedActions');
      appContent.style.marginBottom = (fixedActionsHeight ? (fixedActionsHeight.offsetHeight) : 0) + 'px';
    }, 500);

    return () => {
      appContent.removeAttribute('style');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [validationState]);

  useEffect(() => {
    setOptions([]);
  }, [sourceDocument.speciesId]);

  useEffect(() => {
    setOptions((prevState) => prevState.map((item) => {
      const isPermitMoveExemption = sourceDocument.permitMove && Object.values(constants.permitMovesMandatory).some((mandatoryExemption) => mandatoryExemption.id === item.id);

      return {
        ...item,
        value: isPermitMoveExemption,
        disabled: isPermitMoveExemption
      };
    }));

    if (sourceDocument.permitMove) {
      setDepartData({
        ...departData,
        numberReceived: departData.numberReceived
      });
    } else {
      setArriveData({
        ...emptyStates.arriveData,
        numberReceived: arriveData.numberReceived
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sourceDocument.permitMove]);

  useEffect(() => {
    const unListen = history.listen((path) => {
      if (path.pathname.substring(0, Routing.boMovements.length) !== Routing.boMovements || location.pathname.length !== Routing.boMovements.length) {
        storeService.session.remove.dataChanged();
        storeService.session.removeAll.movement();
        resetPage(true);
        unListen();
      }
    });

    window.onpopstate = () => {
      if (storeService.session.get.confirm()) {
        history.push(Routing.boMovements);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history]);

  useEffect(() => {
    if (location?.state?.data?.data?.id) {
      const data = location.state.data.data;
      if (data.notes) {
        setUserNotes(data.notes.map((note) => ({
          date: note.noteDate,
          text: note.notes,
          user: note.user
        })));
      }

      const isPmMove = storeService.session.get.confirmPmMove();
      const sourceDocumentSection = createDocumentSection(data);
      const doc = data.movementDocument;
      const departureSection = createDepartureSection(doc.departureDetail);
      const transportSection = createTransportSection(doc.transportDetail);
      const destinationSection = createDestinationSection(doc.destinationDetail);
      const batches = createBatchesSection(data.movementGroups[0].batches);
      const devices = createDevicesSection(data.movementGroups[0].devices);

      if (isPmMove) {
        sourceDocumentSection.permitMove = true;
        departureSection.manualCPH = data.comment;
        departureSection.numberReceived = destinationSection.numberReceived;
      }

      setSourceDocumentInit(sourceDocumentSection);
      setSourceDocument(sourceDocumentSection);
      setTransportData(transportSection);
      setTransportDataInit(transportSection);
      setArriveData(isPmMove ? emptyStates.arriveData : destinationSection);
      setArriveDataInit(isPmMove ? emptyStates.arriveData : destinationSection);
      setOptionsInit(data.processingFlags);
      const movementExemptions = data.processingFlags.map((exemption) => ({
        id: exemption.processingFlagId,
        value: true
      }));
      storeService.session.set.movementExemptions(movementExemptions);
      setNeedUpdate(true);
      setBatchList(batches);
      setBatchListInit(batches);
      setAnimalDetails(devices);
      setAnimalDetailsInit(devices);
      //Don't move up as the departureDetail will not update
      setDepartData(departureSection);
      setDepartDataInit(departureSection);
      validatePaperMovement();
      setPostType(constants.postType.UPDATE);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  useEffect(() => {
    helpers.scrollToTop(document.getElementById('appContent'));
  }, []);

  const displayConfirmation = () => {
    const paper_Id = storeService.session.get.confirmPaperId();
    const movement_Ref = storeService.session.get.confirmMovementReference();
    const errors = storeService.session.get.confirmPollingErrors();
    const warnings = storeService.session.get.confirmPollingWarnings();
    const status = storeService.session.get.confirmPollingStatus();
    const submitType = storeService.session.get.confirmSubmitType();

    const isUndo = helpers.postType.isUndo(submitType);
    const withErrors = errors?.length > 0;
    const withWarnings = warnings?.length > 0;

    const onClick = {
      searchByMovementReference: () => helpers.redirect.searchByMovementReference(history, movement_Ref),

      searchByPaperId: () => helpers.redirect.searchByPaperId(history, paper_Id),

      paperMovement: () => {
        bff
          .get('/paperDocument', {
            params: {
              id: paper_Id
            }
          })
          .then((res) => {
            helpers.redirect.paperMovement(history, paper_Id, {
              data: {
                data: res.data.data[0]
              }
            });
          })
          .catch((error) => {
            errors.BFF(error, setModal);
          });
      },

      clickEvent: () => {
        if (isUndo) {
          return null;
        }

        switch (submitType) {
          case constants.postType.UNDO:
            return onClick.searchByPaperId();
          case constants.postType.CREATE:
          case constants.postType.UPDATE:
          default:
            return movement_Ref ? onClick.searchByMovementReference() : (paper_Id ? onClick.paperMovement() : null);
        }
      }
    };

    const getConfirmId = () => {
      switch (submitType) {
        case constants.postType.UNDO:
          return paper_Id;
        case constants.postType.CREATE:
        case constants.postType.UPDATE:
        default:
          return movement_Ref ?? paper_Id ?? null;
      }
    };
    const confirmStatus = status ?? constants.status.success;
    const label = bffStatus.isSuccess(confirmStatus) ? !isUndo ? 'label.submissionPaperSuccess' : 'label.submissionUndoPaperSuccess' : helpers.confirmation.getTitle(withErrors, withWarnings, movement_Ref);

    return (
      <Confirmation
        buttons={[
          {
            id: isUndo ? 'undoConfirm' : 'confirm',
            label: 'button.recordAnotherMovement',
            onClick: recordAnother
          }
        ]}
        confirm={{
          content: isUndo ? 'label.paperMovementCancelled' : helpers.confirmation.getContent(withErrors, withWarnings, movement_Ref),
          footer: (isUndo ? null : {
            label: !paper_Id && !movement_Ref ? (!status ? '' : 'label.paperMovementCancelled') : 'label.amendMovementContent',
            onClick: onClick.clickEvent
          }),
          id: getConfirmId(),
          isUndo,
          label: helpers.confirmation.getConfirmIdLabel(postType, movement_Ref, paper_Id),
          onClick: onClick.clickEvent
        }}
        errors={errors}
        label={label}
        setModal={setModal}
        status={confirmStatus}
        warnings={warnings}
      />
    );
  };

  const stepPicker = (pathname) => {
    if (pathname === Routing.boMovementsConfirm) {
      return (
        <>
          {!pending && displayConfirmation()}
        </>
      );
    } else if (pathname.substring(0, Routing.boMovements.length) === Routing.boMovements) {
      return (
        <>
          {(!submitted ||
            (
              submitted &&
              !pending &&
              (
                (helpers.postType.isCreate(postType) && !asyncId) ||
                (helpers.postType.isUpdate(postType) && !asyncId) ||
                (helpers.postType.isUndo(postType) && !requestId)
              )
            )
          ) &&
            <>
              <Prompt
                message={(params) => {
                  return (!storeService.session.get.idle() && dataChanged && (params.pathname.substring(0, Routing.boMovements.length) !== Routing.boMovements || params.pathname === Routing.boMovements)) ? t('boApp.label.confirmNavigateAwayAmendChanges') : true;
                }}
              />

              {submitted && !pending && // Submission error
                <Confirmation
                  errors={pageErrors}
                  label="label.submissionError"
                  setModal={setModal}
                  status={constants.status.error}
                />
              }

              {undoStatus &&
                <ModalConfirm
                  id="undoMovement"
                  modalClose={() => setUndoStatus(false)}
                  modalConfirm={() => {
                    submit(constants.postType.UNDO);
                  }}
                  modalMessage="boApp.label.confirmUndoPaperMovement"
                  modalTitle="button.undo"
                />
              }

              <PageTitle
                id="paperDetailsTitle"
                pageTitleOne="boApp.navMenu.movements"
                pageTitleTwo={t(sourceDocument.paperId ? 'boApp.pageTitles.movements.editMovement' : 'boApp.pageTitles.movements.createMovement') + ' ' + sourceDocument.paperId}
              />
              <div className="pageContent">
                {pageContent.sourceDocumentSection()}
                {pageContent.departureLocationSection()}
                {pageContent.transportSection()}
                {sourceDocument.permitMove ? null : pageContent.arrivalLocationSection()}
                {pageContent.exemptionsSection()}
                {pageContent.animalBatchSection()}
                {pageContent.individualAnimalsSection()}
                {pageContent.userNotesSection()}

                <div className={classes.itemsTotal}>
                  <i className={'bi bi-list-check ' + classes.icon} />
                  <span className={classes.label}>{t('label.animalTotal')} {String(batchList.reduce((total, item) => total + parseInt(item.animalTotal), 0) + animalDetails?.length)} </span>
                </div>
              </div>

              <div className={classes.fixedActions} id="fixedActions">
                {validationState === 1 && pageContent.pendingDisplay()}
                {validationState === 2 && pageContent.resultsDisplay()}
                <div className="actions fixedActions">
                  <div className="left">
                    <Button
                      buttonType="secondary"
                      id="validateButton"
                      label="button.validate"
                      onClick={validatePaperMovement}
                    />
                  </div>
                  <div className="action">
                    <Button
                      buttonType="primary"
                      disabled={!sourceDocumentProps.isValid || validationState !== 2}
                      id="movementSubmitButton"
                      label="button.submit"
                      onClick={() => submit(postType)}
                    />
                  </div>
                  <div className="right">
                    {sourceDocument.paperId &&
                      <Button
                        buttonType="danger"
                        id="deleteButton"
                        label="button.undo"
                        onClick={() => setUndoStatus(true)}
                      />
                    }

                    <Button
                      buttonType="tertiary"
                      id="cancelButton"
                      label="button.reset"
                      onClick={(e) => {
                        e.preventDefault();
                        resetPage(true);
                      }}
                    />
                  </div>
                </div>
              </div>
            </>
          }

          {submitted && pending &&
            <Confirmation
              label="label.submissionPending"
              setModal={setModal}
              status={constants.status.pending}
            />
          }
        </>
      );
    }
  };

  return (
    <>
      {ready && stepPicker(location.pathname)}
    </>
  );
};

BoPaperDetails.propTypes = {
  location: PropTypes.object.isRequired,
  setModal: PropTypes.func.isRequired
};

export default BoPaperDetails;
